import React from 'react';
import { Seo } from 'components';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';
import { config } from 'config';

const NotAllowedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  text-align: center;
  background-color: ${props => props.theme.colors.light100};
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: ${props => props.theme.colors.primary};
`;

const Message = styled.p`
  font-size: 1.2rem;
  max-width: 600px;
  margin-bottom: 2rem;
  line-height: 1.6;
`;
const NotAllowedPage = () => (
  <NotAllowedWrapper>
    <Seo title="Access Not Allowed" />
    <Title>Access Not Allowed</Title>
    <Message>
      We&apos;re sorry, but our services are not available in your region at
      this time. Thank you for your interest in our product.
    </Message>
  </NotAllowedWrapper>
);

export default NotAllowedPage;
